import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { GradeTable, IStudentGradeRow } from "./components/GradeTable";
import { calcAllFinalGrade } from "./utils/calculate_grade";
import { BASE_API_URL, GET_DEFAULT_HEADERS, MY_BU_ID } from "./globals";
import { IClass } from "./types/api_types";

function App() {
  const [currClassId, setCurrClassId] = useState<string>("");
  const [classList, setClassList] = useState<IClass[]>([]);
  const [currFinalGrades, setCurrFinalGrades] = useState<IStudentGradeRow[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        `${BASE_API_URL}/class/listBySemester/fall2022?buid=${MY_BU_ID}`,
        { method: "GET", headers: GET_DEFAULT_HEADERS() }
      );
      const json = await res.json();
      setClassList(json);
    };
    fetchData().catch(console.error);
  }, []);

  const handleSelection = async (event: SelectChangeEvent) => {
    setCurrClassId(event.target.value);
    setCurrFinalGrades([]);
    setIsLoading(true);
    setCurrFinalGrades(await calcAllFinalGrade(event.target.value));
    setIsLoading(false);
  };

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <Grid container spacing={2} style={{ padding: "1rem" }}>
        <Grid
          xs={12}
          container
          // spacing={0}
          // direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h2" gutterBottom>
            Spark Assessment
          </Typography>
        </Grid>
        <Grid xs={12} md={4}>
          <Typography variant="h4" gutterBottom>
            Select a class
          </Typography>
          <div style={{ width: "100%" }}>
            <Select
              fullWidth={true}
              value={currClassId}
              label="Class"
              onChange={handleSelection}
            >
              {classList.map((klass, idx) => {
                return (
                  <MenuItem key={idx} value={klass.classId}>
                    {klass.title}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        </Grid>
        <Grid xs={12} md={8}>
          <Typography variant="h4" gutterBottom>
            Final Grades
          </Typography>
          <GradeTable StudentGrades={currFinalGrades} loading={isLoading} />
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
