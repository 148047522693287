import { IStudentGradeRow } from "../components/GradeTable";
import { BASE_API_URL, GET_DEFAULT_HEADERS, MY_BU_ID } from "../globals";
import { IClass, IClassAssignments, IStudentGrades } from "../types/api_types";

export async function getClassByID(classID: string): Promise<IClass> {
  const res = await fetch(
    `${BASE_API_URL}/class/GetById/${classID}?buid=${MY_BU_ID}`,
    { method: "GET", headers: GET_DEFAULT_HEADERS() }
  );
  return res.json();
}

export async function getClassAssignments(
  classID: string
): Promise<IClassAssignments[]> {
  /**
   * This function calls the API and retrieves the weights for a given class
   *
   */
  const res = await fetch(
    `${BASE_API_URL}/class/listAssignments/${classID}?buid=${MY_BU_ID}`,
    { method: "GET", headers: GET_DEFAULT_HEADERS() }
  );
  return res.json();
}

export async function getStudentGrades(
  studentID: string,
  classID: string
): Promise<IStudentGrades> {
  const res = await fetch(
    `${BASE_API_URL}/student/listGrades/${studentID}/${classID}?buid=${MY_BU_ID}`,
    { method: "GET", headers: GET_DEFAULT_HEADERS() }
  );
  return res.json() as Promise<IStudentGrades>;
}

export async function getStudentsInClass(classID: string): Promise<string[]> {
  const res = await fetch(
    `${BASE_API_URL}/class/listStudents/${classID}?buid=${MY_BU_ID}`,
    {
      method: "GET",
      headers: GET_DEFAULT_HEADERS(),
    }
  );
  return res.json();
}

export async function calculateStudentFinalGrade(
  studentID: string,
  classAssignments: IClassAssignments[],
  klass: IClass
): Promise<IStudentGradeRow> {
  const studentGrades = await getStudentGrades(
    studentID,
    classAssignments[0].classId
  );
  studentGrades.grades = [new Map(Object.entries(studentGrades.grades[0]))];
  let finalGrade = 0;
  // We assume that if the grade isn't on the students profile they got a zero
  classAssignments.forEach((assignment) => {
    finalGrade +=
      (assignment.weight / 100) *
      (studentGrades.grades[0].get(assignment.assignmentId) || 0);
  });
  const outputStudentGrade: IStudentGradeRow = {
    id: studentID,
    ClassID: klass.classId,
    ClassName: klass.title,
    FinalGrade: finalGrade,
    StudentID: studentID,
    StudentName: studentGrades.name,
    Semester: klass.semester,
  };
  return outputStudentGrade;
}

export async function calcAllFinalGrade(
  classID: string
): Promise<IStudentGradeRow[]> {
  const allStudentsInClass = await getStudentsInClass(classID);
  const classAssignments = await getClassAssignments(classID);
  let allStudentGrades: IStudentGradeRow[] = [];
  const klass = await getClassByID(classID);
  for (const student of allStudentsInClass) {
    const currStudentFinalGrade = await calculateStudentFinalGrade(
      student,
      classAssignments,
      klass
    );
    allStudentGrades.push(currStudentFinalGrade);
  }
  return allStudentGrades;
}
