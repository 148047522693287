import { DataGrid, GridColDef } from "@mui/x-data-grid";

export interface IStudentGradeRow {
  id: string;
  StudentID: string;
  StudentName: string;
  ClassID: string;
  ClassName: string;
  FinalGrade: number;
  Semester: string;
}

interface IGradeTableProps {
  StudentGrades: IStudentGradeRow[];
  loading: boolean;
}

export function dummyData(): IStudentGradeRow[] {
  return [
    {
      id: "1",
      StudentID: "U123",
      StudentName: "John Doe",
      ClassID: "C123",
      ClassName: "Test Class",
      FinalGrade: 5,
      Semester: "Fall 2022",
    },
    {
      id: "2",
      StudentID: "U124",
      StudentName: "Jain Doe",
      ClassID: "C123",
      ClassName: "Test Class",
      FinalGrade: 15,
      Semester: "Fall 2022",
    },
  ];
}

export const GradeTable = ({ StudentGrades, loading }: IGradeTableProps) => {
  const columns: GridColDef[] = [
    { field: "StudentID", headerName: "Student ID" },
    { field: "StudentName", headerName: "Student Name", width: 150 },
    { field: "ClassID", headerName: "Class ID" },
    { field: "ClassName", headerName: "Class Name" },
    { field: "Semester", headerName: "Semester" },
    { field: "FinalGrade", headerName: "Final Grade", type: "number" },
  ];

  return (
    <div style={{}}>
      <DataGrid
        loading={loading}
        rows={StudentGrades}
        columns={columns}
        autoHeight={true}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
      />
    </div>
  );
};
